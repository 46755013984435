import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

let routes = [];
// routes = [
//   {
//     path: '/',
//     name: 'Home',
//     component: () => import('../views/Home.vue')
//   },
// ]
if(window.innerHeight > 910){
  routes = [
    {
      path: '/',
      name: 'Home',
      component: () => import('../views/Home.vue')
    },
  ]
} else {
  routes = [
    {
      path: '/',
      name: 'Mobile',
      component: () => import('../views/Mobile.vue')
    },
  ]
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
