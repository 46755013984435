<template>
  <div id="app" class="main">
    <div class="main-content" :class="{'mobile-mode': ismobileMode}">
      <keep-alive>
          <router-view />
      </keep-alive>
    </div>
    <div class="main-nav" v-if="!ismobileMode">
      <berryan-nav></berryan-nav>
    </div>
  </div>
</template>
<script>
import berryanNav from '@/components/nav/nav.vue'
export default {
  data(){
    return {
      ismobileMode: false
    }
  },
  components: {
    berryanNav
  },
  computed: {
  },
  mounted(){
      if(window.innerHeight <= 910){
          this.ismobileMode = true;
      }
  }
}
</script>
<style lang="scss">
body{
  background-color: rgba(240,240,240,0.3);
}
.main{
  font-family: 'Noto Sans TC', 'Noto Sans', sans-serif;
  position: relative;
  &-nav{
    height: 100vh;
    width: 100px;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1001;
    background-color: rgba(255,255,255);
  }
  &-content{
    padding-left: 100px;
    z-index: 1;
    height: 100vh;
    &.mobile-mode{
      padding-left: 0;
      height: auto;
    }
    
    // background-color: rgba(0,0,255,0.1);
  }
}
</style>
