<template>
    <div class="nav">
        <div class="nav-button-group">
            <button type="button" class="nav-button logo-zh" @click="gotoPage('introduction')">
                <i class="nav-button-icon"></i>
            </button>
            <button type="button" class="nav-button logo-switch"  @click="openMenu()">
                <i class="nav-button-icon" :class="{'rotate': isMenuOpen}"></i>
            </button>
            <button type="button" class="nav-button logo-en" @click="gotoPage('about')">
                <i class="nav-button-icon"></i>
            </button>
        </div>
        <div class="nav-menu" :class="{'d-none': !isMenuOpen}">
            <header class="nav-menu-link-group">
                <button type="button" class="nav-menu-link-group-item" @click="gotoPage('introduction')">公司介紹</button>
                <button type="button" class="nav-menu-link-group-item" @click="gotoPage('product')">產品介紹</button>
                <button type="button" class="nav-menu-link-group-item" @click="gotoPage('unify')">服務概況</button>
                <button type="button" class="nav-menu-link-group-item" @click="gotoPage('about')">聯絡我們</button>
            </header>
            <div class="nav-menu-bottom">
                <div class="nav-menu-bottom-warning">建議解析度 1920 X 1080</div>
                <div class="nav-menu-bottom-copyright">Copyright © Berryan Inc.</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'berryanNav',
    data(){
        return {
            isMenuOpen: false,
        }
    },
    methods: {
        openMenu(){
            this.isMenuOpen = !this.isMenuOpen;
        },
        gotoPage(item){
            $('.home').animate({scrollLeft: document.getElementById(item).offsetLeft}, 500);
            this.isMenuOpen = false;
        }
    },
}
</script>
<style lang="scss">
.nav{
    height: 100%;
    position: relative;
    &-button-group{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .nav-button{
            width: 42px;
            height: 42px;
            box-sizing: border-box;
            &:not(.logo-zh){
                padding: 9px;
                border-radius: 50%;
                &:hover{
                    background-color: rgba(0,0,0,0.25);
                }
            }
            &.logo-zh{
                margin-top: 10px;
                width: 71px;
                height: 84px;
                i{
                    display: inline-block;
                    width: 60px;
                    height: 84px;
                    background: url('~@/assets/nav/logo-zh.svg') center center /contain no-repeat;
                }
            }
            &.logo-switch{
                i{
                    &.rotate{
                        transform: rotateZ(90deg);
                    }
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    background: url('~@/assets/nav/switch.svg') center center /contain no-repeat;
                }
            }
            &.logo-en{
                margin-bottom: 30px;
                i{
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    background: url('~@/assets/nav/logo-en.svg') center center /contain no-repeat;
                }
            }
        }
    }
    &-menu{
        &:not(.d-none){
            display: block;
        }
        padding: 20px 0;
        text-align: center;
        display: none;
        position: absolute;
        width: 240px;
        left: 100px;
        height: 100%;
        top: 0;
        &-link-group{
            display: flex;
            flex-direction: column;
            height: calc(100% - 85px);
            align-items: center;
            justify-content: center;
            &-item{
                padding-bottom: 20px;
                font-size: 24px;
            }
        }
        &-bottom{
            &-warning{
                margin-bottom: 20px;
            }
        }
        background-color: #fff;
    }
}
</style>
